import {
  GET_LATEST_TERMS_OF_SERVICE,
  GET_LATEST_TERMS_OF_SERVICE_SUCCESS,
  GET_LATEST_TERMS_OF_SERVICE_ERROR,
  CREATE_USER_TERMS_OF_SERVICE,
  CREATE_USER_TERMS_OF_SERVICE_SUCCESS,
  CREATE_USER_TERMS_OF_SERVICE_ERROR
} from "./actionTypes"


// For Get latest terms-of-service
export const getLatestTermsOfService = () => ({
  type: GET_LATEST_TERMS_OF_SERVICE,
})

export const getLatestTermsOfServiceSuccess = (response) => ({
  type: GET_LATEST_TERMS_OF_SERVICE_SUCCESS,
  payload: response
})

export const getLatestTermsOfServiceError = (error) => ({
  type: GET_LATEST_TERMS_OF_SERVICE_ERROR,
  payload: error
})

export const createUserTermsOfService = (data) => ({
  type: CREATE_USER_TERMS_OF_SERVICE,
  payload: data
})

export const createUserTermsOfServiceSuccess = (response) => ({
  type: CREATE_USER_TERMS_OF_SERVICE_SUCCESS,
  payload: response
})

export const createUserTermsOfServiceError = (error) => ({
  type: CREATE_USER_TERMS_OF_SERVICE_ERROR,
  payload: error
})