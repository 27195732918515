import {
  GET_LATEST_TERMS_OF_SERVICE_ERROR,
  GET_LATEST_TERMS_OF_SERVICE,
  GET_LATEST_TERMS_OF_SERVICE_SUCCESS,
  CREATE_USER_TERMS_OF_SERVICE,
  CREATE_USER_TERMS_OF_SERVICE_SUCCESS,
  CREATE_USER_TERMS_OF_SERVICE_ERROR
} from "./actionTypes"

const INIT_STATE = {
  latest_terms_of_service: null,
  latest_terms_of_service_loading: null,
  latest_terms_of_service_error: null,
  terms_of_services: [],
  downloaded_tos_url: null,
  downloaded_tos_url_loading: false,
  downloaded_tos_url_error: null,
  loading: false,
  error: null,
  user_terms_of_service: [],
  user_terms_of_service_loading: false,
  user_terms_of_service_error: null
}

const TermsOfServices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LATEST_TERMS_OF_SERVICE:
      return {
        ...state,
        latest_terms_of_service_loading: true,
        latest_terms_of_service_error: null,
      }

    case GET_LATEST_TERMS_OF_SERVICE_SUCCESS:
      return {
        ...state,
        latest_terms_of_service: action.payload,
        latest_terms_of_service_loading: false,
      }

    case GET_LATEST_TERMS_OF_SERVICE_ERROR:
      return {
        ...state,
        latest_terms_of_service_loading: false,
        latest_terms_of_service_error: action.payload,
      }
    case CREATE_USER_TERMS_OF_SERVICE:
      return {
        ...state,
        user_terms_of_service_loading: true,
        user_terms_of_service_error: null,
      }

    case CREATE_USER_TERMS_OF_SERVICE_SUCCESS:
      return {
        ...state,
        user_terms_of_service_loading: false,
        user_terms_of_service: [...state.user_terms_of_service, action.payload.data],
      }

    case CREATE_USER_TERMS_OF_SERVICE_ERROR:
      return {
        ...state,
        user_terms_of_service_loading: false,
        user_terms_of_service_error: action.payload,
        user_terms_of_service: [],
      }

    default:
      return state
  }
}

export default TermsOfServices;
