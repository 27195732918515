import React, { useState } from "react"
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu
} from "reactstrap"

//i18n
// Redux
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { logoutAdminUser } from "store/admin/Authentication/login/actions"

const AdminUserProfileMenu = props => {
    const [menu, setMenu] = useState(false)

    const { current_user } = useSelector(state => ({
        current_user: state.LoginAdminUser.current_user
    }));

    const dispatch = useDispatch();
    const history = useNavigate();

    const onClickLogout = () => {
        dispatch(logoutAdminUser(history))
    }
    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle className="btn header-item" tag="button" id="page-header-user-dropdown">
                    <span className="d-none d-lg-inline-block ms-2 me-1 text-black">{current_user?.email}</span>
                    <i className="mdi mdi-chevron-down d-none text-black d-xl-inline-block" />
                    <i className="bx bx-user d-lg-none d-inline-block" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                    <Link to="#" onClick={onClickLogout} className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                        <span>{("Logout")}</span>
                    </Link>

                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

export default AdminUserProfileMenu
