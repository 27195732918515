import React, { useEffect, useMemo, useState } from "react";
import { MetaTags } from 'react-meta-tags';
import { Card, CardBody, Col, Container, FormGroup, Label, Row } from 'reactstrap';
import DateRangePicker, {
  DATE_RANGE_OPTIONS, createOption,
} from '../../components/common/DateRangePicker';
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/common/Breadcrumb";
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { tooltipForColumnHeader, dateCreatedFormatter, timeFormatter, CcCommaStringFormatter, getIANATimezoneStringForAbbreviation, SfPercentStringFormatter, SfCommaStringFormatter, SfDollarStringFormatter, findbyGroupByKeys, percIncrease } from "../../helpers/utlis"
import '../../assets/scss/theme.scss';
import '../../assets/css/datatables.scss'
import { campaignStatsComparedDataRequest, fetchCampaignsRequest, getCampaignStatRequest } from "store/actions";
import _ from 'lodash';
import CustomSkeleton from "components/common/CustomSkeleton";
import { REPORTING_TIMEZONE } from "helpers/constants";
import { changePrevTimeRangeBySelectedTimeRange, getPrevDataRangeFromSelectedDataRange } from "helpers/campaign_helper";
import { TYPES_OF_DATA_FORMAT } from "helpers/constants";
import { customSortCaret } from "helpers/bootstrap_table_helper";
import { useTranslation } from "react-i18next";
import i18n_keys from "i18n_keys";

function Campaigns() {
  const dispatch = useDispatch();
  const { campaignList, campaignStats, loading, campaignStatsComparedData, loadingCampaignStats } = useSelector((state) => ({
    campaignList: state.campaignReducer.campaignList,
    campaignStats: state.campaignReducer.campaignStats,
    loading: state.campaignReducer.loading,
    loadingCampaignStats: state.campaignReducer.loadingCampaignStats,
    campaignStatsComparedData: state.campaignReducer.campaignStatsComparedData,
  }));
  const [compare, setCompare] = useState(false);


  useEffect(() => {
    dispatch(fetchCampaignsRequest())
    dispatch(getCampaignStatRequest())
  }, []);

  const { t } = useTranslation();

  let tableData = useMemo(() => {
    const lookup = campaignStats.reduce((acc, obj) => {
      acc[obj.campaign_id] = obj;
      return acc;
    }, {});
    const joinedArray = campaignList.map(item => (
      {
        ...item,
        average_duration: lookup[item.id]?.average_duration,
        total_certificates: lookup[item.id]?.total_certificates,
        completed: lookup[item.id]?.completed
      }));
    return joinedArray
  }, [campaignList, campaignStats, campaignStatsComparedData]);

  const [prevselectedDateRange, setPrevSelectedDateRange] = useState(createOption(
    DATE_RANGE_OPTIONS.LAST_MONTH,
    new Date(),
    getIANATimezoneStringForAbbreviation(REPORTING_TIMEZONE),
  ));

  const formatExtraDataObj = {
    compare_to_stats: campaignStatsComparedData,
  };

  const [selectedDateRange, setSelectedDateRange] = useState(createOption(
    DATE_RANGE_OPTIONS.TODAY,
    new Date(),
    getIANATimezoneStringForAbbreviation('America/Los_Angeles'),
  ));

  useEffect(() => {
    const queryParams = {};
    const dateRangeValue = _.cloneDeep(selectedDateRange?.value);
    if (dateRangeValue?.startDate) {
      queryParams.start_date = dateRangeValue.startDate.toISOString();
    }
    if (dateRangeValue?.endDate) {
      queryParams.end_date = dateRangeValue.endDate.toISOString();
    }
    const prev_date_range_options = getPrevDataRangeFromSelectedDataRange(
      selectedDateRange,
      getIANATimezoneStringForAbbreviation(REPORTING_TIMEZONE)
    );
    setPrevSelectedDateRange(prev_date_range_options[0]);

    if (compare) {
      fetchData(selectedDateRange, prev_date_range_options[0], compare);
    } else {
      dispatch(getCampaignStatRequest({ queryParams }));
    }
  }, [selectedDateRange, compare]);


  const fetchData = (selectedDateRange, prevselectedDateRange, compare) => {
    let clonePrevSelectedDateRange = _.cloneDeep(prevselectedDateRange)
    let prevSelectedEndDate = clonePrevSelectedDateRange.value.endDate
    let cloneSelctedDateRange = _.cloneDeep(selectedDateRange)
    const queryParams = {};
    const dateRangeValue = _.cloneDeep(selectedDateRange?.value);
    if (dateRangeValue?.startDate) {
      queryParams.start_date = dateRangeValue.startDate.toISOString();
    }
    if (dateRangeValue?.endDate) {
      queryParams.end_date = dateRangeValue.endDate.toISOString();
    }
    dispatch(getCampaignStatRequest({ queryParams }));
    if (compare) {
      const prevDateRangeValue = _.cloneDeep(prevselectedDateRange?.value);
      const queryParams = {};
      if (prevDateRangeValue?.startDate) {
        queryParams.start_date = prevDateRangeValue.startDate.toISOString();
      }
      if (prevDateRangeValue?.endDate) {
        prevSelectedEndDate = changePrevTimeRangeBySelectedTimeRange(clonePrevSelectedDateRange, cloneSelctedDateRange)
        queryParams.end_date = prevSelectedEndDate.toISOString();
      }
      dispatch(campaignStatsComparedDataRequest({ queryParams }));
    }
  }

  const columns = [
    {
      dataField: 'name',
      text: 'Campaign',
      headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
        return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'name', 'Name', t(i18n_keys.CAMPAIGN_NAME_TOOLTIP_MESSAGE))
      },
      sort: true,
      formatter: (_cellContent, row) => {
        return (
          <div className="d-flex align-items-center">
            <Link to={`/campaigns/${row.id}`}>
              <div className="fw-medium text-ccblue text-truncate text-capitalize" style={{ maxWidth: "250px", color: '#2f9ee9' }}>
                {row.name}
              </div>
            </Link>
          </div>
        )
      },
    },
    {
      dataField: 'total_certificates',
      text: 'Certificates',
      headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
        return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'certificates', 'Certificates', t(i18n_keys.CERTIFICATE_TOOLTIP_MESSAGE))
      },
      sort: true,
      formatter: (_cellContent, row, _rowIndex, formatExtraData) => {
        return compareToStatsFormatter(row, 'total_certificates', formatExtraData);
      },
      formatExtraData: {
        ...formatExtraDataObj,
        data_format: TYPES_OF_DATA_FORMAT.COMMA
      },
    },
    {
      dataField: 'completed',
      text: 'Completed Certificates',
      headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
        return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'completed_certificates', 'Completed Certificates', t(i18n_keys.COMPLETED_CERTIFICATE_TOOTIP_MESSAGE))
      },
      sort: false,
      formatter: (_cellContent, row, _rowIndex, formatExtraData) => {
        return compareToStatsFormatter(row, 'completed', formatExtraData);
      },
      formatExtraData: {
        ...formatExtraDataObj,
        data_format: TYPES_OF_DATA_FORMAT.COMMA
      },
    },
    {
      dataField: 'created_at',
      text: 'Created',
      headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
        return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'created', 'Created', t(i18n_keys.CAMPAIGN_DATE_TOOLTIP_MESSAGE))
      },
      sort: true,
      formatter: dateCreatedFormatter,
    },
    {
      dataField: 'average_duration',
      text: 'Avg Duration',
      headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
        return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, 'avg_duration', 'Avg Duration', t(i18n_keys.AVG_DURATION_TOOLTIP_MESSAGE))
      },
      sort: true,
      formatExtraData: {
        ...formatExtraDataObj,
        data_format: TYPES_OF_DATA_FORMAT.TIME
      },
      formatter: (_cellContent, row, _rowIndex, formatExtraData) => {
        return compareToStatsFormatter(row, 'average_duration', formatExtraData);
      },
    },
  ];

  const getFormattedValue = (value, data_format) => {
    if (data_format === TYPES_OF_DATA_FORMAT.PERCENTAGE) {
      return SfPercentStringFormatter(value)
    } else if (data_format === TYPES_OF_DATA_FORMAT.COMMA) {
      return SfCommaStringFormatter(value)
    } else if (data_format === TYPES_OF_DATA_FORMAT.DOLLAR) {
      return SfDollarStringFormatter(value)
    } else if (TYPES_OF_DATA_FORMAT.TIME) {
      return timeFormatter(value)
    }
    else {
      return value
    }
  }

  const getDiffPercent = (prevData, currentData, data_format) => {
    if ([TYPES_OF_DATA_FORMAT.DOLLAR, TYPES_OF_DATA_FORMAT.COMMA, TYPES_OF_DATA_FORMAT.PERCENTAGE, TYPES_OF_DATA_FORMAT.TIME].includes(data_format)) {
      return parseFloat(prevData) ? SfPercentStringFormatter(Math.abs(percIncrease(prevData, currentData)).toFixed(0)) : null
    } else {
      return 0
    }
  }

  const compareToStatsFormatter = (row, dataField, formatExtraData) => {
    const currentData = row?.[dataField] || 0
    const currentDataFormatter = getFormattedValue(currentData, formatExtraData?.data_format)
    if (compare) {
      const compare_to_obj = findbyGroupByKeys(formatExtraData?.compare_to_stats, 'campaign_id', row.id) || null
      const prevData = compare_to_obj?.[dataField] || 0
      const prevDataFormatter = getFormattedValue(prevData, formatExtraData?.data_format)
      const diffPercent = getDiffPercent(prevData, currentData, formatExtraData?.data_format)
      return (
        <div className='d-flex align-content-center'>
          {currentDataFormatter}
          {' '}
          {`(${prevDataFormatter})`}
          <div className="d-flex align-content-center">
            {diffPercent && ((currentData - prevData) > 0 ?
              <div className='ms-2 text-success'>
                <i className="fa fa-caret-up text-success me-1" />
                {diffPercent}
              </div>
              :
              (prevData - currentData) > 0 ?
                <div className='ms-2 sfred'>
                  <i className='fa fa-caret-down sfred me-1' />
                  {diffPercent}
                </div>
                : ''
            )}
          </div>
        </div>
      );
    } else {
      return currentDataFormatter
    }
  }
  const getDefaultSorted = () => {
    return [{
      dataField: 'name',
      order: 'asc'
    }];
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>All Campaigns {t(i18n_keys.PAGE_TITLE_SUFFIX)}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Campaigns" />
          <Card>
            <CardBody>
              <Row className="mb-2">
                <Col md='3' className="align-self-top">
                  <div>
                    <FormGroup className="mb-0">
                      <Label className="visually-hidden">Date :</Label>
                      <DateRangePicker
                        ianaTz={getIANATimezoneStringForAbbreviation('America/Los_Angeles')}
                        value={selectedDateRange}
                        options={Object.keys(DATE_RANGE_OPTIONS).map((opt) => createOption(
                          DATE_RANGE_OPTIONS[opt],
                          new Date(),
                          getIANATimezoneStringForAbbreviation('America/Los_Angeles'),
                        ))}
                        onChange={(value) => {
                          setSelectedDateRange(value);
                        }}
                      />
                    </FormGroup>
                  </div>
                </Col>
                <Col md='auto' className="align-self-center">
                  <div>
                    <FormGroup className="mb-0 sf-checkbox">
                      <input
                        className="me-2"
                        type="checkbox"
                        checked={compare}
                        id='campaign-compare-checkbox'
                        onClick={() => {
                          setCompare(!compare);
                        }}
                      />
                      <label htmlFor='campaign-compare-checkbox' style={{ cursor: "pointer" }} className="text-black">Compared to</label>
                    </FormGroup>
                  </div>
                </Col>
                <Col md='3' className="align-self-top">
                  <div>
                    <FormGroup className="mb-0">
                      <Label className="visually-hidden">Date :</Label>
                      <DateRangePicker
                        ianaTz={getIANATimezoneStringForAbbreviation('America/Los_Angeles')}
                        value={prevselectedDateRange}
                        options={Object.keys(DATE_RANGE_OPTIONS).map((opt) => createOption(
                          DATE_RANGE_OPTIONS[opt],
                          new Date(),
                          getIANATimezoneStringForAbbreviation('America/Los_Angeles'),
                        ))}
                        onChange={(value) => {
                          setPrevSelectedDateRange(value);
                          fetchData(selectedDateRange, prevselectedDateRange, compare);
                        }}
                      />
                    </FormGroup>
                  </div>
                </Col>
                <Col md='4' className="align-self-end">
                  <div>
                    <FormGroup className="mb-0 float-end">
                      <div id='new_campaign_button'>
                        <Link
                          className={`btn btn-primary`}
                          to="/campaigns/create"
                        >
                          <i className="bx bx-plus font-size-14 align-middle me-2" />
                          New Campaign
                        </Link>
                      </div>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              <Row >
                <Col xl="12" className="mt-3">
                  {loading || loadingCampaignStats ? <CustomSkeleton rows={8} cols={4} /> :
                    <BootstrapTable
                      keyField='id'
                      data={tableData}
                      responsive
                      bordered={false}
                      striped={false}
                      rowClasses="tr-hover"
                      headerClasses="text-black"
                      classes="table align-middle table-nowrap text-black"
                      headerWrapperClasses="thead-light"
                      noDataIndication={t(i18n_keys.NO_CAMPAIGNS)}
                      columns={columns}
                      defaultSorted={getDefaultSorted()}
                      sort={{ sortCaret: customSortCaret }}
                    />}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Campaigns;
