import {
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  RESEND_USER_INVITE,
  RESEND_USER_INVITE_SUCCESS,
  RESEND_USER_INVITE_ERROR,
  RESET_USER_INVITE_SENT_TO,
  GET_USERS,
  GET_CURRENT_ACCOUNT,
  GET_CURRENT_ACCOUNT_SUCCESS,
  GET_CURRENT_ACCOUNT_ERROR,
} from "./actionTypes"

const INIT_STATE = {
  current_account: null,
  users: [],
  error: null,
  roles: [],
  invite_sent_to: null,
  loading: false
}

const accounts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        loading: false
      }

    case GET_USERS:
      return {
        ...state,
        loading: true,
      }

    case GET_USERS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload]
      }

    case ADD_USER_ERROR:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.id === action.payload.user.id ? { ...user, ...action.payload.user } : user
        ),
      };


    case UPDATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          user => user.id.toString() !== action.payload.user.id.toString()
        ),
      }

    case DELETE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
      }

    case RESEND_USER_INVITE:
      return {
        ...state,
        error: null,
      }

    case RESEND_USER_INVITE_SUCCESS:
      return {
        ...state,
        error: null,
        invite_sent_to: action.payload
      }

    case RESEND_USER_INVITE_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case RESET_USER_INVITE_SENT_TO:
      return {
        ...state,
        invite_sent_to: null,
        error: null
      }
    case GET_CURRENT_ACCOUNT:
      return {
        ...state,
        error: null
      }

    case GET_CURRENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        error: null,
        current_account: action.payload
      }

    case GET_CURRENT_ACCOUNT_ERROR:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}

export default accounts
