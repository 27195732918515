import { Col, Row } from "reactstrap"

import PropTypes from "prop-types"
import React from "react";

const ClientBreadCrumb = ({loadingState, title, userCount, userCountOption, copyUrl }) => {
  return (
    <Row>
      <Col md={12} xs="12">
        <div className="page-title-box pb-2 d-sm-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center ">
            <h4 className="text-capitalize text-black mb-0 font-size-18">
              {title}
            </h4>
            {userCountOption &&
              !loadingState && (<span className="ms-3 text-black" style={{marginTop:'3px'}}>{userCount} Users</span>)
            }
          </div>
        </div>
      </Col>
    </Row>
  )
}

ClientBreadCrumb.propTypes = {
  title: PropTypes.string,
  userCount: PropTypes.any,
  loadingState: PropTypes.any
}
export default ClientBreadCrumb
