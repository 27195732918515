export const RETAIN_CERTIFICATE_TIME = [
  {
    id: 1,
    label: "1 Year"
  },
  {
    id: 2,
    label: "2 Years"
  },
  {
    id: 3,
    label: "3 Years"
  },
  {
    id: 4,
    label: "4 Years"
  }
]

export const RETAIN_CERTIFICATE_TIME_OPTIONS = RETAIN_CERTIFICATE_TIME.map(element => ({ value: element.id, label: element.label }));

export const ACCOUNT_OWNER = "Account Owner"

export const USER_INVITE_STR = {
  RESEND_SUCCESS_MSG: 'Invite sent successfully.',
  RESEND_FAILURE_MSG: 'Failed to send invite.',
  RESEND_ERROR_TYPE: 'resend_failure'
}

export const ERR_DEVISE_SIGNATURE_VERIFICATION_FAILED = "ERR_DEVISE_SIGNATURE_VERIFICATION_FAILED";

export const VIEW_MODE_TYPE = {
  MINI: 'mini',
  DETAILED: 'detailed'
}

export const MODE = {
  CREATE: 'create',
  EDIT: 'edit'
}

export const APEX_GRAPH_MINI_OPTIONS = {
  chart: {
    zoom: { enabled: !1 },
    toolbar: { show: !1 },
    type: 'line',
    background: 'transparent',
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350
      }
    }
  },
  colors: ["#2f9ee9", "#D3D3D3"],
  dataLabels: { enabled: false },
  stroke: { width: [1.5, 1.5], curve: "straight" },
  xaxis: {
    show: false,
    labels: {
      show: false
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    }
  },
  yaxis: {
    show: false,
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 800,
      options: { chart: { toolbar: { show: !1 } }, legend: { show: !1 } },
    },
  ],
}

export const APEX_GRAPH_DETAILED_OPTIONS = {
  chart: {
    zoom: { enabled: !1 },
    toolbar: { show: !1 },
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350
      }
    }
  },
  colors: ["#2f9ee9", "#D3D3D3"],
  dataLabels: { enabled: false },
  stroke: { width: [2, 2, 2, 2], curve: "straight" },
  grid: {
    row: { colors: ["transparent", "transparent"], opacity: 0.2 },
    borderColor: "#f1f1f1",
  },
  markers: {
    size: 3,
    hover: {
      size: 6,
      sizeOffset: 3
    }
  },
  yaxis: {
    show: false,
  },
  legend: {
    position: "top",
    horizontalAlign: "right",
    floating: !0,
    offsetY: 0,
    offsetX: -10,
  },
  responsive: [
    {
      breakpoint: 600,
      options: { chart: { toolbar: { show: !1 } }, legend: { show: !1 } },
    },
  ]
}

export const COMPARED_MULTIPLE_GRAPH_COLORS = ["#2f9ee9", "#c2def1", '#068c06', '#add9ad']

export const MULTIPLE_GRAPH_COLORS = ["#2f9ee9", '#4CAD84']

export const REPORTING_TIMEZONE = "America/Los_Angeles";

export const TIMEFRAME_TYPES = {
  hour: 'hour',
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year'
}

export const DASHBOARD_METRIC_NAMES = {
  'SESSIONS': 'Sessions',
  'AVG_DURATION': 'Avg Duration',
  'DEVICE_TYPE': 'Device Type',
  'BROWSER_TYPE': 'Browser Type',
  'TOP_DOMAINS': 'Top Domains',
  'TOP_STATES': 'Top States'
}

export const METRIC_TYPES = {
  'SESSIONS': 'total_certificates',
  'DURATION': 'average_duration',
  'COMPLETED': 'total_completed',
}

export const META_TYPES = {
  'CURRENT': 'current',
  'PREV': 'prev',
  'SUCCESS_CURRENT': 'success_current',
  'SUCCESS_PREV': 'success_prev',
}

export const METRIC_TYPE_NAMES = {
  'total_certificates': 'Sessions',
  'average_duration': 'Avg Duration',
}

export const TYPES_OF_DATA_FORMAT = {
  PERCENTAGE: 'percentage',
  COMMA: 'comma',
  DOLLAR: 'dollar',
  COMMA_WITH_TWO_DECIMAL_PLACES: 'comma_with_two_decimal_places',
  PERCENTAGE_WITH_TWO_DECIMAL_PLACES: 'percentage_with_two_decimal_places',
  TIME: 'TIME'
}

export const CAMPAIGNS_GROUP_BY_KEYS = [
  'campaign_name'
]
export const FILTER_COMPONENT_TYPES = {
  TEXT: "text",
  EMAIL: "email",
  CHECKBOX: "checkbox",
  DATE_RANGE: "date_range",
  SINGLE_SELECT: "single_select",
  MULTI_SELECT: "multi_select",
  COMPARATOR: "comparator"
}


export const STATUS_TYPES = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  IN_PROGRESS: 'in-progress',
  FAILED: 'failed',
  NO_ANSWER: 'no-answer',
  BUSY: 'busy'
}

export const COMPARISON_FIELD_OPTIONS = [
  { label: 'Greater Than', value: "Greater Than" },
  { label: 'Less Than', value: "Less Than" },
  { label: 'Equal To', value: "Equal To" }
]

export const convertStringifiedNullToNull = (obj) => {
  for (let key in obj) {
    if (obj[key] === "null") {
      obj[key] = null;
    }
  }
  return obj
}


export const YES_AND_NO = {
  YES: "yes",
  NO: "no"
}

export const DEFAULT_START_TIME = "0001-01-01T00:00:00Z"

export const NO_DATA_MESSAGE = "No Data. Please check the timeframe or other filters."

export const ADMIN_USER_DEPARTMENT_OPTIONS = [
  { label: "Account Management", value: "Account Management" },
  { label: "Business Development", value: "Business Development" },
  { label: "Contact Center", value: "Contact Center" },
  { label: "Engineering", value: "Engineering" },
  { label: "Executive", value: "Executive" },
  { label: "Iksha Labs", value: "Iksha Labs" },
  { label: "Marketing", value: "Marketing" },
  { label: "Sales", value: "Sales" },
  { label: "Accounting", value: "Accounting" },
]

export const ADMIN_SIGNUP_FIELD_NAMES = {
  EXTENSION: 'extension',
  JOB_TITLE: 'job_title',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  PASSWORD: 'password',
  TEXT: 'text',
  OTP: 'otp'
};

export const KEYBOARD_PRESS_CODES = {
  ENTER: "Enter"
};

export const LATEST = 'latest';

export const LOGIN_AS_USER_KEY = {
  ACCESS_ADMIN_TOKEN: 'access_admin_token',
  ADMIN_ACCESS: 'admin_access',
  ACCESS_USER_TOKEN: 'access_user_token'
};

export const OTP_STRINGS = {
  authenticator_message: 'Please enter the OTP from your authenticator app',
  email_message: 'Please check the OTP sent to your email'
}

export const DOCUMENT_LINK = ` https://main.d26ykncymjb6dn.amplifyapp.com/docs/form-completion`