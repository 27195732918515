import { errorMessages } from 'common/utils/data_validations';
import { STORAGE_KEY } from 'helpers/cc_browser_storage_helper';
import { lazy } from "react"
export const percentageIncrease = (oldVal, newVal) => {
  return oldVal ? ((newVal - oldVal) / oldVal) * 100.0 : 0;
}

export const STATUS_OPTIONS = {
  ACTIVE: "active",
  PAUSED: "paused",
  ONBOARDING: "onboarding",
  INACTIVE: "inactive",
  REGISTRATION_PENDING: 'registration_pending'
}

export const capitalizeWord = (word) => {
  if (word && typeof word === 'string') {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  return '';
};

export const handleSessionTimeOut = (error) => {
  if (error && error === errorMessages.ERR_DEVISE_SIGNATURE_HAS_EXPIRED) {
    localStorage.removeItem(STORAGE_KEY.AUTH_TOKEN);
    window.location = '/login?session_expired=1';
  }
};

export const lazyWithRetry = componentImport =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("page-has-been-force-refreshed") || "false"
    )

    try {
      const component = await componentImport()

      window.localStorage.setItem("page-has-been-force-refreshed", "false")

      return component
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem("page-has-been-force-refreshed", "true")
        //ToastAlert.fire({ icon: 'info', title: 'There is an app update for this page... Fetching new version' });
        return window.location.reload()
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error
    }
  })

