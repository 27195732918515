import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  NEW_PASSWORD,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_ERROR,
  CREATE_PASSWORD,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_ERROR,
} from "./actionTypes"

export const userForgetPassword = (user, navigate) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, navigate },
  }
}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}

export const newPassword = (user, navigate) => {
  return {
    type: NEW_PASSWORD,
    payload: { user, navigate },
  }
}

export const newPasswordSuccess = msg => {
  return {
    type: NEW_PASSWORD_SUCCESS,
    payload: msg,
  }
}

export const newPasswordError = error => {
  return {
    type: NEW_PASSWORD_ERROR,
    payload: error,
  }
}

export const createPassword = (user, navigate) => {
  return {
    type: CREATE_PASSWORD,
    payload: { user, navigate },
  }
}

export const createPasswordSuccess = message => {
  return {
    type: CREATE_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const createPasswordError = message => {
  return {
    type: CREATE_PASSWORD_ERROR,
    payload: message,
  }
}
