import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MetaTags } from 'react-meta-tags';
import { Card, Container, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/common/Breadcrumb";
import { RETAIN_CERTIFICATE_TIME_OPTIONS } from "../../helpers/constants";
import { getCampaignByIdRequest, updateCampaignRequest } from "../../store/campaigns/actions";
import CampaignForm from "components/CampaignComponent/CampaignForm";
import i18n_keys from "i18n_keys";
import { t } from "i18next";
import StateGraphLoader from "components/common/StateGraphLoader";

const retainCertificateOptions = RETAIN_CERTIFICATE_TIME_OPTIONS;

function EditCampaign() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { campaign, loading } = useSelector(state => ({
    campaign: state.campaignReducer.campaignById,
    loading: state.campaignReducer.loading
  }));
  const [selectedRetainCertificate, setSelectedRetainCertificate] = useState(null);
  const [campaignName, setCampaignName] = useState('');
  const [consentConfirmationToken, setConsentConfirmationToken] = useState('');

  useEffect(() => {
    dispatch(getCampaignByIdRequest(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (campaign) {
      setCampaignName(campaign?.data?.name);
      setSelectedRetainCertificate({
        value: campaign?.data?.retain_certificate_for,
        label: `${campaign?.data?.retain_certificate_for} ${campaign?.data?.retain_certificate_for === 1 ? 'Year' : 'Years'}`
      });
      setConsentConfirmationToken(campaign?.data?.api_token);
    }
  }, [campaign]);

  const handleRetainCertificateChange = selectedOption => {
    setSelectedRetainCertificate(selectedOption);
  };

  const handleNameCampaignChange = e => {
    setCampaignName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      id,
      name: campaignName,
      retain_certificate_for: selectedRetainCertificate.value
    };
    dispatch(updateCampaignRequest(requestData))
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Edit Campaign {t(i18n_keys.PAGE_TITLE_SUFFIX)}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Edit Campaign" link={"/campaigns"} backOption={true} />
          <Row>
            <Col className="col-12">
              {loading ?
                <StateGraphLoader />
                : <CampaignForm
                  campaignName={campaignName}
                  selectedRetainCertificate={selectedRetainCertificate}
                  handleNameCampaignChange={handleNameCampaignChange}
                  handleRetainCertificateChange={handleRetainCertificateChange}
                  handleSubmit={handleSubmit}
                  retainCertificateOptions={retainCertificateOptions}
                  consentConfirmationToken={consentConfirmationToken}
                />}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default EditCampaign;
