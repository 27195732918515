import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  SET_USER_TO_BE_CREATED,
  RESET_REGISTRATION_STATE
} from './actionTypes';

const initialState = {
  error: null,
  loading: false,
  status: null,
  UserInfo: null,
  campaignDetails: null
};

const authRegister = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_USER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        campaignDetails: action.payload.additional_data.campaigns
      };
    case REGISTER_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_USER_TO_BE_CREATED:
      return {
        ...state,
        loading: false,
        UserInfo: action.payload,
      };
    case RESET_REGISTRATION_STATE:
      return initialState;
    default:
      return state;
  }
};

export default authRegister;
