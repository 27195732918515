import React, { useState } from "react";
import { Card, CardBody, CardText, Row } from "reactstrap";
import ToastAlert, { ToastAlertIconType } from "components/common/ToastAlert/ToastAlert";

const ToggleCardComponent = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.token);
    ToastAlert.fire({ icon: ToastAlertIconType.OK, title: "Copied" });
    toggleCard();
  };

  const toggleCard = () => {
    setIsOpen((prev) => !prev);
  };


  return (
    <Row className="px-2">
      {!isOpen && (
        <button className={`btn btn-primary`} onClick={toggleCard}>
          {props.buttonText}
        </button>
      )}

      {isOpen && (
        <Card className="p-0 m-0">
          <CardBody className="bg-token-header d-flex align-items-center justify-content-center p-2">
            <CardText className="mb-0">
              {props.token}
            </CardText>

            <button color="success" className={`btn btn-primary mx-2`} onClick={() => { copyToClipboard(); }}>
              <i className="bx bx-copy"></i>
            </button>

          </CardBody>
        </Card>
      )}

    </Row>
  );
};

export default ToggleCardComponent;
