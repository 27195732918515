import { STORAGE_KEY } from 'helpers/cc_browser_storage_helper';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { logoutAdminAccess } from 'store/admin/Authentication/login/actions';

const ClientUserProfileMenu = ({ currentClientUser }) => {
    const dispatch = useDispatch();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const history = useNavigate()

    const adminAccessLogout = () => {
        localStorage.removeItem(STORAGE_KEY.FILTERS.NAME)
        dispatch(logoutAdminAccess(history));
        window.close();
    }
    return (
        <div className="d-flex">
            <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle
                    className="btn header-item"
                    tag="button"
                    id="page-header-user-dropdown"
                    onClick={toggleDropdown}
                    aria-expanded={isDropdownOpen ? 'true' : 'false'}
                >
                    <span className="d-none d-lg-inline-block ms-2 me-1 text-black">
                        {currentClientUser?.data?.email}
                    </span>
                    <i className="mdi mdi-chevron-down d-none text-black d-xl-inline-block" />
                    <i className="bx bx-user d-lg-none d-inline-block" />
                </DropdownToggle>
                <DropdownMenu right>
                    {
                        (localStorage.getItem(STORAGE_KEY.ADMIN_ACCESS) && localStorage.getItem(STORAGE_KEY.AUTH_TOKEN)) ?
                            (
                                <Link to="#" onClick={adminAccessLogout} className="dropdown-item">
                                    <i className="bx bx-undo font-size-16 align-middle me-1 text-danger" />
                                    <span>Return to Original Account</span>
                                </Link>
                            ) : (
                                <Link to="/logout" className="dropdown-item">
                                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                                    <span>Logout</span>
                                </Link>
                            )
                    }
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default ClientUserProfileMenu;
