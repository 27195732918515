import { call, put, takeEvery } from "redux-saga/effects"

import { LOGIN_ADMIN_USER, LOGOUT_ADMIN_USER, GET_ADMIN_USER_CURRENT_USER, LOGOUT_ADMIN_ACCESS, UPDATE_ADMIN_USER_CURRENT_USER } from "./actionTypes";
import { loginAdminUserSuccess, logoutAdminUserSuccess, apiError, getAdminUserCurrentUserSuccess, getAdminUserCurrentUserError, logoutAdminAccessSuccess, updateAdminUserCurrentUserSuccess, updateAdminUserCurrentUserError } from "./actions"

import { AppConfiguration } from "config"
import { genericDeleteRequest, genericPutRequest_2, getCurrentUser, logoutAdminRequest, postJwtAdminLogin } from "helpers/backend_helper"
import { STORAGE_KEY, bsh_clearAdminUserDetails, bsh_getAdminUserDetails, bsh_setAdminUserDetails } from "helpers/cc_browser_storage_helper";
import { OTP_STRINGS } from "helpers/constants";
import { jwtDecode } from "jwt-decode";


function* loginAdminUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      postJwtAdminLogin,
      `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/admin_login`,
      user
    )

    bsh_setAdminUserDetails({ token: response.data.token })
    history('/client-users')
    yield put(loginAdminUserSuccess(response.data))
  } catch (error) {
    if (error === OTP_STRINGS.authenticator_message || error === OTP_STRINGS.email_message) {
      history(`/login?email=${user?.admin_user?.email}&showOtp="true"`)
    }
    yield put(apiError(error))
  }
}

function* logoutAdminUserSaga({ payload: { history } }) {
  try {
    let token = bsh_getAdminUserDetails().token;
    const response = yield call(
      logoutAdminRequest,
      `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/admin_logout`,
      {
        headers: {
          Authorization: token,
        }
      }
    )
    bsh_clearAdminUserDetails();
    yield put(logoutAdminUserSuccess())
    history("/login")
    window.location.reload();
  } catch (error) {
    yield put(apiError(error))
  }
}

function* onGetCurrentUser({ payload: { history } }) {
  try {
    let token = bsh_getAdminUserDetails().token;
    let decodedToken = jwtDecode(token.split(" ")[1]);

    const response = yield call(
      getCurrentUser,
      `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/admin_users/profiles/${decodedToken.sub}`,
      {
        token: token
      }
    )
    yield put(getAdminUserCurrentUserSuccess(response))
  } catch (error) {
    yield put(getAdminUserCurrentUserError(error))
    bsh_clearAdminUserDetails();
  }
}

function* onUpdateCurrentUser(action) {
  try {
    let adminUserInfo = bsh_getAdminUserDetails();
    const response = yield call(
      genericPutRequest_2,
      `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/admin_signup`,
      {
        body: JSON.stringify({
          ...action.payload
        }),
        headers: {
          Authorization: adminUserInfo.token,
          'Content-Type': 'application/json'
        }
      }
    )
    yield put(updateAdminUserCurrentUserSuccess(response))
  } catch (error) {
    yield put(updateAdminUserCurrentUserError(error))
  }
}


function* logoutAdminAccessSaga({ payload: { history } }) {
  try {
    localStorage.removeItem(STORAGE_KEY.AUTH_TOKEN)
    bsh_clearAdminUserDetails();

    yield put(logoutAdminAccessSuccess())
    if (history?.redirect_url) {
      window.location.assign("/" + history?.redirect_url)
    }
    else {
      window.location.assign("/login")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* AdminUserLoginSaga() {
  yield takeEvery(LOGIN_ADMIN_USER, loginAdminUser)
  yield takeEvery(LOGOUT_ADMIN_USER, logoutAdminUserSaga)
  yield takeEvery(GET_ADMIN_USER_CURRENT_USER, onGetCurrentUser)
  yield takeEvery(UPDATE_ADMIN_USER_CURRENT_USER, onUpdateCurrentUser)
  yield takeEvery(LOGOUT_ADMIN_ACCESS, logoutAdminAccessSaga)
}

export default AdminUserLoginSaga
