import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
// import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { currentUserRequest } from "store/actions";
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/images/logo.png";
import logoSmall from "../../assets/images/logo-small.png";

// // Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
// import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
// import megamenuImg from "../../assets/images/megamenu-img.png";

// // import images
// import github from "../../assets/images/brands/github.png";
// import bitbucket from "../../assets/images/brands/bitbucket.png";
// import dribbble from "../../assets/images/brands/dribbble.png";
// import dropbox from "../../assets/images/brands/dropbox.png";
// import mail_chimp from "../../assets/images/brands/mail_chimp.png";
// import slack from "../../assets/images/brands/slack.png";

// import logo from "../../assets/images/logo.png";
// import logoLightSvg from "../../assets/images/logo-light.svg";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import { STORAGE_KEY, bsh_getClientAuthToken, bsh_isAdminUserDetailsPresent } from 'helpers/cc_browser_storage_helper';
import { getAdminUserCurrentUser } from 'store/admin/Authentication/login/actions';
import ClientUserProfileMenu from 'components/CommonForBoth/TopbarDropdown/ClientUserProfileMenu';
import AdminUserProfileMenu from 'components/CommonForBoth/TopbarDropdown/AdminUserProfileMenu';
import { openLinkInNewTab } from 'helpers/utlis';
import ToggleCardComponent from 'components/common/ToggleCardComponent';
import { DOCUMENT_LINK } from 'helpers/constants';

const Header = () => {
  const dispatch = useDispatch();
  const [isAdminLayout, setIsAdminLayout] = useState(false)
  const currentClientUser = useSelector(state => state.currentUserReducer.currentUser);

  const { current_account, current_user } = useSelector(state => ({
    current_account: state.accounts.current_account,
    current_user: state.authReducer.current_user
  }));

  useEffect(() => {
    if (bsh_isAdminUserDetailsPresent()) {
      dispatch(getAdminUserCurrentUser());
    }
    if (localStorage.getItem(STORAGE_KEY.AUTH_TOKEN)) {
      dispatch(currentUserRequest());
    }
  }, []);

  // function toggleFullscreen() {
  //   if (
  //     !document.fullscreenElement &&
  //     /* alternative standard method */ !document.mozFullScreenElement &&
  //     !document.webkitFullscreenElement
  //   ) {
  //     // current working methods
  //     if (document.documentElement.requestFullscreen) {
  //       document.documentElement.requestFullscreen();
  //     } else if (document.documentElement.mozRequestFullScreen) {
  //       document.documentElement.mozRequestFullScreen();
  //     } else if (document.documentElement.webkitRequestFullscreen) {
  //       document.documentElement.webkitRequestFullscreen(
  //         Element.ALLOW_KEYBOARD_INPUT
  //       );
  //     }
  //   } else {
  //     if (document.cancelFullScreen) {
  //       document.cancelFullScreen();
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen();
  //     } else if (document.webkitCancelFullScreen) {
  //       document.webkitCancelFullScreen();
  //     }
  //   }
  // }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  useEffect(() => {
    if (bsh_isAdminUserDetailsPresent() && !localStorage.getItem(STORAGE_KEY.AUTH_TOKEN)) {
      setIsAdminLayout(true)
    }
  }, [])
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSmall} alt="" height="22" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            {
              localStorage.getItem(STORAGE_KEY.ADMIN_ACCESS) && (
                <div className="font-size-18 align-self-center text-black">
                  Admin View: Logged into
                  <strong className='custom-green'>{" "}
                    {current_account && current_account?.name || ""}
                  </strong>
                  {" "}
                  {current_account && current_account?.name && "#" + current_account?.id || ""}
                </div>
              )
            }
          </div>

          <div className="d-flex align-items-center">


            {bsh_getClientAuthToken() ? <ToggleCardComponent token={current_user?.account?.api_token} buttonText={"View Token"} /> : null}
            <Link
              style={{ cursor: 'pointer', color: '#2FB4E9', marginLeft: '24px' }}
              to='#'
              onClick={() => openLinkInNewTab(DOCUMENT_LINK)
              }
            >
              Documentation
            </Link>
            {
              isAdminLayout ? <AdminUserProfileMenu /> :
                <ClientUserProfileMenu
                  currentClientUser={currentClientUser} />
            }

          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
