import { AppConfiguration } from "../config";

export const generateCampaignSnippet = (apiToken) => {
  return `<script>
    (function () 
    {
      var s = document.createElement('script');
      s.id = 'xxConsentConfirmationxx';
      if (document.getElementById('xxConsentConfirmationxx')) {
          return;
      }
      s.type = 'text/javascript';
      s.async = true;
      s.src = "${AppConfiguration?.CONSENT_CAPTURE_SCRIPT_URL}";
      var ss = document.getElementsByTagName('script')[0];
      ss.parentNode.insertBefore(s, ss);
      window.xxConsentConfirmationTokenxx = '${apiToken}';
    })()
</script>`;
};
