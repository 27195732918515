import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"

import * as Yup from "yup"
import { useFormik } from "formik"

import {
  createPassword,
  newPassword,
} from "../../store/actions"
import { AppConfiguration } from "config"
import logoDark from "assets/images/logo-dark.png";
import { t } from "i18next";
import i18n_keys from "i18n_keys";
import { MODE } from "helpers/constants";

const ResetPasswordPage = props => {

  const { resetPasswordError, resetPasswordSuccessMsg, loading } = useSelector(
    state => ({
      resetPasswordError: state.resetPassword.resetPasswordError,
      resetPasswordSuccessMsg: state.resetPassword.resetPasswordSuccessMsg,
      loading: state.resetPassword.loading,
    })
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const url = window.location.search;
  const urlParams = new URLSearchParams(location.search)
  const token = urlParams.get("create_password_token") || urlParams.get("reset_password_token")
  const account_id = parseInt(urlParams.get("account_id"))
  const invited_user = urlParams.get("invited_user")
  let mode = props?.create ? MODE.CREATE : MODE.EDIT;

  const title = mode == MODE.CREATE ? 'Create Password' : 'Reset Password'
  const validation = useFormik({
    enableReinitialize: false,

    initialValues: {
      password: "",
      password_confirmation: "",
      reset_password_token: token,
      account_id: account_id
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please Enter your password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      password_confirmation: Yup.string()
        .required("Please Re-Enter your password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: values => {
      const passwordReducer = mode === MODE.CREATE ? createPassword : newPassword
      dispatch(passwordReducer(values, navigate))
    },
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>{title} | {AppConfiguration.WEBSITE}</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-white bg-soft bg-soft-primary">
                  <Row>
                    <Col xs={12}>
                      <div className="text-center p-4">
                        <img src={logoDark} height={70} />
                        <br />
                        {!props?.create && (<><span className="mt-2">
                          {" "}
                          {t(i18n_keys.GET_FREE_CONSENT_CONFIRMATION_ACCOUNT)}
                        </span>
                          <br /></>)}
                        <span className="mt-2">
                          {invited_user && t(i18n_keys.CREATE_NEW_PASSWORD_TO_PROCEED)}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    {resetPasswordError && resetPasswordError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {resetPasswordError}
                      </Alert>
                    ) : null}
                    {resetPasswordSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {resetPasswordSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {/* Password field starts */}
                      <div className="mb-3">
                        <Label className="form-label font-weight-semibold">New Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder={mode == MODE.CREATE ? t(i18n_keys.CREATE_PASSWORD) : t(i18n_keys.ENTER_NEW_PASSWORD)}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                              validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                          validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      {/* Password field ends */}

                      {/* Confirm New Password field starts */}
                      <div className="mb-3">
                        <Label className="form-label font-weight-semibold">
                          Confirm New Password
                        </Label>
                        <Input
                          name="password_confirmation"
                          value={validation.values.password_confirmation || ""}
                          type="password"
                          placeholder={t(i18n_keys.RE_ENTER_NEW_PASSWORD)}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password_confirmation &&
                              validation.errors.password_confirmation
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password_confirmation &&
                          validation.errors.password_confirmation ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password_confirmation}
                          </FormFeedback>
                        ) : null}
                      </div>
                      {/* Confirm New Password field ends */}

                      <div className="mb-3 d-grid">
                        <button
                          className={`btn btn-primary btn-block`}
                          type="submit"
                        >
                          {loading ? "Please wait..." : (mode == MODE.CREATE ? "Submit" : "Reset")}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center text-white">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-sfblue">
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear() + " " + AppConfiguration.WEBSITE}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ResetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default ResetPasswordPage;
