export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR"

export const NEW_PASSWORD = "NEW_PASSWORD"
export const NEW_PASSWORD_SUCCESS = "NEW_PASSWORD_SUCCESS"
export const NEW_PASSWORD_ERROR = "NEW_PASSWORD_API_ERROR"

export const CREATE_PASSWORD = "CREATE_PASSWORD"
export const CREATE_PASSWORD_SUCCESS = "CREATE_PASSWORD_SUCCESS"
export const CREATE_PASSWORD_ERROR = "CREATE_PASSWORD_ERROR"