import React, { useState } from "react";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import PropTypes from 'prop-types';
import { AppConfiguration } from 'config';
import { LATEST } from "helpers/constants";

const PromptTermsOfServiceModal = ({ termsOfService, onAcceptClick, onCloseClick }) => {
    const [isWatchingTermsOfUse, setIsWatchingTermsOfUse] = useState(false);
    const [isReject, setIsReject] = useState(false);

    const onRejectClick = (e) => {
        e.preventDefault();

        if (isReject) {
            onCloseClick();
            return;
        }
        setIsWatchingTermsOfUse(false);
        setIsReject(true);
    }

    return (
        <Modal size={isWatchingTermsOfUse ? "xl" : "md"} isOpen={termsOfService.isActive} centered={true}>
            <ModalHeader>
                <div className="d-flex justify-content-center align-items-center">
                    <i className={`align-self-bottom label-title bx bx-receipt custom-green me-1`}></i>
                    <span className="modal-title text-black font-weight-semibold">
                        Terms of Service 
                    </span>
                </div>
            </ModalHeader>
            <ModalBody className="noscrollbar"> 
                <Row>
                    <Col xl={12}>{
                        isReject ? (
                            <div className="mb-3">
                                <p className="font-size-13">
                                    <a className="text-sfblue" href={AppConfiguration.WEBSITE}>{AppConfiguration.WEBSITE}</a> requires that you read and agree to our terms of service in order to use the platform. If you have questions you can contact us at  <a className="text-sfblue" href={`mailto: ${AppConfiguration.INFO_MAIL}`}>{AppConfiguration.INFO_MAIL}</a>
                                </p>
                            </div>
                        ) : (
                            <div className="mb-3">
                                <p className="font-size-13">
                                    Please review the updated terms of service agreement and press accept to continue.
                                </p>
                                <a href={`${AppConfiguration.RAILS_BACKEND_URL}/api/v1/terms_of_services/${LATEST}/download`} target="_blank" className="text-blue">Terms of Service Agreement</a>
                            </div>
                        )
                    }
                    </Col>
                </Row>
            </ModalBody >
            <ModalFooter>
                <button
                    type="button"
                    className={`btn btn-primary btn-block ms-2`}
                    onClick={() => onAcceptClick(termsOfService?.terms_of_service || null)}
                >
                    Accept
                </button>
                <button
                    type="button"
                    className="btn btn-light ms-2"
                    onClick={onRejectClick}
                >
                    Reject
                </button>
            </ModalFooter>
        </Modal >
    )
}

PromptTermsOfServiceModal.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    termsOfService: PropTypes.any,
    textMessage: PropTypes.object,
    cancelButton: PropTypes.bool
}


export default PromptTermsOfServiceModal
