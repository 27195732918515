import PropTypes from "prop-types"
import { QRCodeSVG } from "qrcode.react";
import { React } from "react"
import { Modal, Form, Label } from "reactstrap";

const ScanQrCodeModal = ({ state, onConfirm, onCloseStateEvent }) => {

    return (
        <Modal isOpen={state.action} toggle={onCloseStateEvent} centered={true} >
            <div className="modal-header">
                <Label className="modal-title mt-0" id="myModalLabel">
                Google App Authentication
                </Label>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={onCloseStateEvent}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <Form>
                <div className="modal-body">
                    <div className="d-flex justify-content-center">
                    {
                        (state?.otp_provisioning_url) && (<QRCodeSVG value={state?.otp_provisioning_url} size={180} className='shadow-lg d-flex flex-row sf-qr-code' />)
                    }
                    </div>
                    <p className="text-center text-black mt-3">Please scan the QR Code above and proceed.</p>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={onConfirm}
                        className="btn btn-primary"
                        data-dismiss="modal"
                        name="save"
                    >
                        Proceed
                    </button>
                    <button
                        type="button"
                        onClick={onCloseStateEvent}
                        className="btn btn-light "
                        data-dismiss="modal"
                        name="reset"
                    >
                        Close
                    </button>
                </div>
            </Form>
        </Modal>
    )
}

ScanQrCodeModal.propTypes = {
    onConfirm: PropTypes.func,
    onCloseStateEvent: PropTypes.func,
    state: PropTypes.object,
}

export default ScanQrCodeModal
