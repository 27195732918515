import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

const SKELETON_COLS = [
    { size: 12, height: 22 },
    { size: 4, height: 12 },
    { size: 8, height: 12 },
    { size: 7, height: 12 },
    { size: 5, height: 12 },
    { size: 3, height: 12 },
    { size: 9, height: 12 },
    { size: 6, height: 12 },
    { size: 6, height: 12 },
    { size: 4, height: 12 },
    { size: 4, height: 12 },
    { size: 4, height: 12 },
];

const StateGraphLoader = () => {
    return (
        <Card className='p-3 py-4 mb-3'>
            <Row>
                {SKELETON_COLS.map(({ size, height }, index) => (
                    <Col key={index} className='mb-3' md={size}>
                        <Skeleton height={height} />
                    </Col>
                ))}
            </Row>
        </Card>
    );
};

export default StateGraphLoader;
