import { takeLatest, call, put } from 'redux-saga/effects';
import { registerSuccess, registerFailure } from './actions';
import { registerUser } from '../../../utils/Api';
import { REGISTER_USER } from './actionTypes';

function* handleRegister(action) {
  const {
    first_name,
    last_name,
    phone,
    company_name,
    address,
    city,
    state,
    zip_code,
    email,
    password,
    accept_terms_of_service,
    is_admin_adding_client,
    send_create_password_email
  } = action.payload;
  try {
    const response = yield call(registerUser,
      first_name,
      last_name,
      phone,
      company_name,
      address,
      city,
      state,
      zip_code,
      email,
      password,
      accept_terms_of_service,
      is_admin_adding_client,
      send_create_password_email);
    yield put(registerSuccess(response));
  } catch (error) {
    yield put(registerFailure(error.message));
  }
}

export function* registerSaga() {
  yield takeLatest(REGISTER_USER, handleRegister);
}
