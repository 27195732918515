import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Routes, Route, useNavigate } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
import 'assets/css/style.css';
// Import Routes all
import { authProtectedRoutes, publicRoutes, adminPublicRoutes, adminUserAuthProtectedRoutes } from "./routes";
import { jwtDecode } from 'jwt-decode';
import useClearableTimeout from 'hooks/useClearableTimeout';
import './assets/scss/theme.scss';
import './assets/scss/datatables.scss';
import './assets/css/table_header.css'
import Authmiddleware from "./routes/route";
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { bsh_clearAdminUserDetails, bsh_getClientAuthToken, bsh_getUserAuthToken, bsh_isAdminUserDetailsPresent, bsh_setAdminUserDetails, STORAGE_KEY } from 'helpers/cc_browser_storage_helper';
import { AppConfiguration } from 'config';
import { LOGIN_AS_USER_KEY } from 'helpers/constants';
import { getCurrentAccount } from 'store/accounts/actions';
import { safeJSONParse } from 'helpers/backend_helper';
import { getCurrentClientUser } from 'store/actions';
import { createUserTermsOfService, getLatestTermsOfService } from 'store/terms-of-service/actions';
import PromptTermsOfServiceModal from 'pages/TermsOfServices/Modal/PromptTermsOfServiceModal';

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App = () => {
  const [userAuthToken, setUserAuthToken] = useState(null);
  const [showTermsOfServiceModal, setShowTermsOfServiceModal] = useState({ isActive: false, terms_of_service: null });
  const dispatch = useDispatch();
  const history = useNavigate();
  const selectLayoutState = (state) => state.Layout;
  const LayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      layoutType: layout.layoutType,
    })
  );

  const { current_user, current_account, latest_terms_of_service } = useSelector((state) => ({
    current_user: state.authReducer.current_user,
    current_account: state.accounts.current_account,
    latest_terms_of_service: state.TermsOfServices.latest_terms_of_service,
  }));
  const authToken = bsh_getUserAuthToken();
  if (authToken != userAuthToken) {
    setUserAuthToken(authToken);
  }
  const navigate = useNavigate();
  const [customSetTimeout] = useClearableTimeout();

  const removeTokenAndNavigate = () => {
    setUserAuthToken(null)
    localStorage.removeItem(STORAGE_KEY.AUTH_TOKEN);
    bsh_clearAdminUserDetails()
  };

  const urlParams = new URLSearchParams(location.search);
  const accessUserToken = urlParams.get(LOGIN_AS_USER_KEY.ACCESS_USER_TOKEN)
  const adminAccess = urlParams.get(LOGIN_AS_USER_KEY.ADMIN_ACCESS)
  const accessAdminToken = urlParams.get(LOGIN_AS_USER_KEY.ACCESS_ADMIN_TOKEN)

  useEffect(() => {
    if (accessUserToken) {
      const parsedToken = safeJSONParse(accessUserToken, {});
      localStorage.setItem(STORAGE_KEY.AUTH_TOKEN, parsedToken?.user?.token);
      localStorage.setItem(STORAGE_KEY.ACCOUNT_ID, parsedToken?.user?.account_id);
      localStorage.setItem(STORAGE_KEY.ADMIN_ACCESS, adminAccess);
      localStorage.removeItem(STORAGE_KEY.FILTERS.NAME)
      bsh_setAdminUserDetails({ token: accessAdminToken });
    }
  }, [accessUserToken]);

  const clientAuthToken = bsh_getClientAuthToken()
  useEffect(() => {
    if (!clientAuthToken) {
      return;
    }
    dispatch(getCurrentClientUser())
  }, [clientAuthToken])
  useEffect(() => {
    if (!current_user) return;
    const accountId = current_user?.account_id
    dispatch(getCurrentAccount(accountId))
    dispatch(getLatestTermsOfService());
  }, [current_user])

  useEffect(() => {
    if (userAuthToken) {
      try {
        const decodedToken = jwtDecode(authToken);
        const currentTime = Date.now();
        const timeUntilExpiry = decodedToken.exp * 1000 - currentTime;

        if (timeUntilExpiry > 0) {
          customSetTimeout(removeTokenAndNavigate, timeUntilExpiry);
        } else {
          removeTokenAndNavigate();
        }
      } catch (error) {
        removeTokenAndNavigate();
      }
    }
  }, [navigate, userAuthToken]);

  useEffect(() => {
    if (
      current_account &&
      latest_terms_of_service &&
      !bsh_isAdminUserDetailsPresent() &&
      current_account?.terms_of_service_id !== latest_terms_of_service?.id
    ) {
      setShowTermsOfServiceModal({ isActive: true, terms_of_service: latest_terms_of_service });
    }
  }, [current_account, latest_terms_of_service]);

  const {
    layoutType
  } = useSelector(LayoutProperties);

  const Layout = getLayout(layoutType);

  const parts = window.location.hostname.split('.').filter(part => part !== 'www');
  const is_admin = !!parts.find((part) => part.includes(AppConfiguration.REACT_ADMIN_HOST_SUBDOMAIN));

  const routes = is_admin
    ? [...adminPublicRoutes, ...adminUserAuthProtectedRoutes]
    : [...publicRoutes, ...authProtectedRoutes];

  const onCloseClick = () => {
    setShowTermsOfServiceModal({ isActive: false, terms_of_service: null });
    history('/logout')
  }

  const onClickAcceptTermOfService = (terms_of_service) => {
    const user_tos_data = {
      terms_of_service_id: terms_of_service?.id,
      name: terms_of_service?.name,
      url: terms_of_service?.url,
      accepted_by: current_user?.id
    }

    dispatch(createUserTermsOfService(user_tos_data))
    setShowTermsOfServiceModal({ isActive: false, terms_of_service: null });
  }
  return (
    <React.Fragment>
      {
        showTermsOfServiceModal?.isActive &&
        (
          <PromptTermsOfServiceModal
            termsOfService={showTermsOfServiceModal}
            onCloseClick={onCloseClick}
            onAcceptClick={onClickAcceptTermOfService}
          />
        )
      }
      <Routes>
        {routes.map((route, idx) => {
          return (
            <Route
              path={route.path}
              element={
                route?.isAuthProtected ? (
                  <Authmiddleware>
                    <Layout>{route.component}</Layout>
                  </Authmiddleware>
                ) : (
                  <NonAuthLayout>{route.component}</NonAuthLayout>
                )
              }
              key={idx}
              exact={true}
            />
          );
        })}

      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;
