import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_USERS,
  ADD_NEW_USER,
  DELETE_USER,
  UPDATE_USER,
  RESEND_USER_INVITE,
  GET_CURRENT_ACCOUNT,
} from "./actionTypes"

import {
  getUsersSuccess,
  getUsersError,
  addUserError,
  deleteUserSuccess,
  deleteUserError,
  resendUserInviteSuccess,
  resendUserInviteError,
  updateUserSuccess,
  updateUserError,
  addUserSuccess,
  getCurrentAccountSuccess,
  getCurrentAccountError,
} from "./actions"

import { AppConfiguration } from "config"
import { genericPostRequest, genericGetRequest, genericPutRequest, genericDeleteRequest, genericPostRequest_2, fetchAccount } from "helpers/backend_helper"
import { USER_INVITE_STR } from "helpers/constants"
import { errorMessages } from "common/utils/data_validations"
import { logoutAdminUser } from "store/admin/Authentication/login/actions"
import { STORAGE_KEY } from "helpers/cc_browser_storage_helper"

function* onGetUsers() {
  try {
    let user_auth = localStorage.getItem("authToken")
    const response = yield call(
      genericGetRequest,
      `${AppConfiguration.BACKEND_URL}/users`,
      {},
      {
        Authorization: user_auth,
      }
    )
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersError(error))
  }
}

function* onUpdateUser(action) {
  try {
    let user_auth = localStorage.getItem("authToken")
    const response = yield call(
      genericPutRequest,
      `${AppConfiguration.BACKEND_URL}/users/${action.payload.id}`,
      { ...action.payload }
    )
    yield put(updateUserSuccess(response))
  } catch (error) {
    yield put(updateUserError(error))
  }
}

function* onDeleteUser(action) {
  try {
    let user_auth = localStorage.getItem("authToken")

    const response = yield call(
      genericDeleteRequest,
      `${AppConfiguration.BACKEND_URL}/users/${action.payload}`,
      {},
      {
        Authorization: user_auth,
      }
    )
    yield put(deleteUserSuccess(response))
  } catch (error) {
    yield put(deleteUserError(error))
  }
}

function* onAddNewUser(action) {
  try {
    const response = yield call(
      genericPostRequest,
      `${AppConfiguration.BACKEND_URL}/users/invite`,
      action.payload
    );
    if (response) {
      yield put(addUserSuccess(response))
    } else {
      yield put(addUserError({ type: USER_INVITE_STR.RESEND_ERROR_TYPE, message: `Failed to send invitation to ${action.payload.email}` }))
    }
  } catch (error) {
    yield put(addUserError({ type: USER_INVITE_STR.RESEND_ERROR_TYPE, message: `Failed to send invitation to ${action.payload.email}` }))
  }
}

function* onResendInvite(user) {
  try {
    const response = yield call(
      genericPostRequest_2,
      `${AppConfiguration.BACKEND_URL}/users/${user.payload.id}/resend_invite`,
      {}
    );

    yield put(resendUserInviteSuccess(response))
  } catch (error) {
    yield put(resendUserInviteError({ type: USER_INVITE_STR.RESEND_ERROR_TYPE, message: `Failed to resend invitation to ${user.payload.email}` }))
  }
}

function* onGetAccount(payload) {
  try {
    const user_auth = localStorage.getItem(STORAGE_KEY.AUTH_TOKEN)
    if (!user_auth) {
      throw "User is not logged in";
    }

    const response = yield call(
      fetchAccount,
      `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/accounts/${payload?.accountId}`,
      {
        token: user_auth,
        account_id: payload?.accountId,
      }
    )

    yield put(getCurrentAccountSuccess(response))
  } catch (error) {
    yield put(getCurrentAccountError(error))
    // redirect to login if this API gives error
    if (error === errorMessages["ERR_DEVISE_SIGNATURE_HAS_EXPIRED"]) {
      yield put(logoutAdminUser({ redirect_url: 'login?session_expired=1' }))
    }
  }
}


function* usersSaga() {
  yield takeEvery(GET_USERS, onGetUsers)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(RESEND_USER_INVITE, onResendInvite)
  yield takeEvery(GET_CURRENT_ACCOUNT, onGetAccount)
}

export default usersSaga
