import React, { useEffect } from "react";
import PropTypes from "prop-types";

//redux
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import withRouter from "components/common/withRouter";
import { bsh_clearAdminUserDetails, STORAGE_KEY } from "helpers/cc_browser_storage_helper";

const Logout = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(location.search);
    const redirect_url = urlParams.get('redirect_url')
    useEffect(() => {
        localStorage.removeItem(STORAGE_KEY.AUTH_TOKEN);
        localStorage.removeItem(STORAGE_KEY.ACCOUNT_ID);
        localStorage.removeItem(STORAGE_KEY.FILTERS.NAME)
        bsh_clearAdminUserDetails()
        history(!!redirect_url ? redirect_url : '/login');
        window.location.reload();

    }, [dispatch, history]);

    return <></>;
};

Logout.propTypes = {
    history: PropTypes.object,
};

export default withRouter(Logout);