import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import { NEW_PASSWORD, FORGET_PASSWORD, CREATE_PASSWORD } from "./actionTypes"
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  newPasswordSuccess,
  newPasswordError,
  createPasswordSuccess,
  createPasswordError,
} from "./actions"

import { AppConfiguration } from "config"

import {
  postJwtForgetPwd,
  postJwtResetPwd,
  putJwtResetPwd,
} from "../../../helpers/backend_helper"


function* forgetUser({ payload: { user } }) {
  try {
      const response = yield call(
        postJwtForgetPwd,
        `${AppConfiguration.BACKEND_URL}/users/forgot_password`,
        {
          email: user.email,
        }
      )
      if (response) {
        yield put(
          userForgetPasswordSuccess("Reset link is sent to your mailbox.")
        )
    } 
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

function* passwordReset({ payload: { user, navigate } }) {
  try {
      yield call(
        postJwtResetPwd,
        `${AppConfiguration.BACKEND_URL}/users/reset_password`,
        {
          account_id: user.account_id,
          reset_password_token: user.reset_password_token,
          password: user.password,
          password_confirmation: user.password_confirmation,
        }
      )
      yield put(newPasswordSuccess("Password Changed Successfully"))
      navigate("/login?flash_message_type=success&flash_message_text=Password Reset successful, please login to proceed.")
  } catch (error) {
    yield put(newPasswordError(error))
  }
}

function* passwordCreate({ payload: { user, navigate } }) {
  try {
    const response = yield call(
      putJwtResetPwd,
      `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/password`,
      {
        email: user.email,
        reset_password_token: user.reset_password_token,
        password: user.password,
        password_confirmation: user.password_confirmation,
      }
    )
    yield put(createPasswordSuccess("Password Created Successfully"))
    navigate("/login?flash_message_type=success&flash_message_text=Password created successfully, please login to proceed.")
  } catch (error) {
    yield put(createPasswordError(error))
  }
}
export function* watchresetPasswordSaga() {
  yield takeEvery(NEW_PASSWORD, passwordReset)
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

export function* watchUserPasswordCreate() {
  yield takeEvery(CREATE_PASSWORD, passwordCreate)
}

function* resetPasswordSaga() {
  yield all([fork(watchresetPasswordSaga)])
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

function* createPasswordSaga() {
  yield all([fork(watchUserPasswordCreate)])
}

export { forgetPasswordSaga, resetPasswordSaga, createPasswordSaga }
