import React, { useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Container, Button } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logoDark from "assets/images/logo-dark.png";
import { resetRegistrationState } from "../../store/auth/register/actions";
import { generateCampaignSnippet } from "utils/campaignSnippet";

const COPY_TIMEOUT = 2000;

const DefaultCampaignSetup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { campaignDetails } = useSelector(state => ({
    campaignDetails: state.authRegister.campaignDetails?.[0] || null
  }));
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!campaignDetails) {
      navigate('/login');
    }

    return () => {
      dispatch(resetRegistrationState());
    };
  }, [campaignDetails, navigate, dispatch]);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(generateCampaignSnippet(campaignDetails?.api_token));
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), COPY_TIMEOUT);
  };

  const handleContinue = () => {
    dispatch(resetRegistrationState());
    navigate('/login?registration=success');
  };

  return (
    <div className="account-pages mt-1 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={6}>
            <Card className="overflow-hidden">
              <div className="bg-white bg-soft">
                <Row>
                  <Col xs={12}>
                    <div className="text-center p-4">
                      <img src={logoDark} height={70} alt="Logo" />
                    </div>
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div className="p-2">
                  <p style={{ textAlign: 'left', marginBottom: '5px' }}>
                    Copy the following code and paste it into the head section of each page you want to capture.
                  </p>
                  <div className="code-snippet-container p-3 bg-light rounded mb-3" style={{ position: 'relative' }}>
                    <Button
                      color="primary"
                      size="sm"
                      style={{ position: 'absolute', top: '10px', right: '10px' }}
                      onClick={handleCopyClick}
                    >
                      {isCopied ? "Copied!" : "Copy Code"}
                    </Button>
                    <pre className="mb-0" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', marginTop: '40px' }}>
                      {generateCampaignSnippet(campaignDetails?.api_token)}
                    </pre>
                  </div>
                  <div className="mt-4 d-grid">
                    <button
                      className="btn btn-primary btn-block"
                      onClick={handleContinue}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DefaultCampaignSetup;
