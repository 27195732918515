import { call, put, takeEvery } from "redux-saga/effects"

import {
    CREATE_USER_TERMS_OF_SERVICE,
    GET_LATEST_TERMS_OF_SERVICE,
} from "./actionTypes"

import {
    getLatestTermsOfServiceSuccess,
    getLatestTermsOfServiceError,
    createUserTermsOfServiceSuccess,
    createUserTermsOfServiceError,
} from "./actions"

import {
    genericGetRequest, postRequestNoDefaultAuth
} from "helpers/backend_helper"

import { AppConfiguration } from "config"
import { STORAGE_KEY, bsh_getClientAuthToken } from "helpers/cc_browser_storage_helper"

function* onGetLatestTermsOfService() {
    try {
        let token = bsh_getClientAuthToken();
        const URL = `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/latest_terms_of_service`;
        const response = yield call(
            genericGetRequest,
            URL,
            {},
            {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        )
        yield put(getLatestTermsOfServiceSuccess(response))
    } catch (error) {
        yield put(getLatestTermsOfServiceError(error))
    }
}

function* onCreateUserTermsOfService(action) {
    try {
        const token = bsh_getClientAuthToken()
        const accountId = localStorage.getItem(STORAGE_KEY.ACCOUNT_ID)
        const response = yield call(
            postRequestNoDefaultAuth,
            `${AppConfiguration.RAILS_BACKEND_URL}/api/v1/user_terms_of_services`,
            {
                body: JSON.stringify({
                    ...action.payload
                }),
                headers: {
                    Authorization: token,
                    'account-id': accountId,
                    'Content-Type': 'application/json',
                },
            }
        )
        yield put(createUserTermsOfServiceSuccess(response))
    } catch (error) {
        yield put(createUserTermsOfServiceError(error))
    }
}

function* TermsOfServicesSaga() {
    yield takeEvery(GET_LATEST_TERMS_OF_SERVICE, onGetLatestTermsOfService)
    yield takeEvery(CREATE_USER_TERMS_OF_SERVICE, onCreateUserTermsOfService)
}

export default TermsOfServicesSaga

