import * as campaignActions from './actionTypes';

const initialState = {
  campaignList: [],
  campaignStats: [],
  campaignStatsComparedData: [],
  campaignById: null,
  loading: false,
  loadingCampaignStats: false,
  error: null,
  updating: false,
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case campaignActions.SAVE_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case campaignActions.SAVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case campaignActions.SAVE_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case campaignActions.FETCH_CAMPAIGNS_REQUEST:
      return {
        ...state,
        campaignList: [],
        loading: true,
        error: null,
      };
    case campaignActions.FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaignList: action.payload || [],
        loading: false,
        error: null,
      };
    case campaignActions.FETCH_CAMPAIGNS_FAILURE:
      return {
        ...state,
        campaignList: [],
        loading: false,
        error: action.payload.error,
      };
    case campaignActions.FETCH_CAMPAIGN_STATS_REQUEST:
      return {
        ...state,
        campaignStats: [],
        loadingCampaignStats: true,
        error: null,
      };
    case campaignActions.FETCH_CAMPAIGN_STATS_SUCCESS:
      return {
        ...state,
        campaignStats: action.payload || [],
        loadingCampaignStats: false,
        error: null,
      };
    case campaignActions.GET_CAMPAIGN_BY_ID_REQUEST:
      return {
        ...state,
        campaignById: null,
        loading: true,
        error: null,
      };
    case campaignActions.GET_CAMPAIGN_BY_ID_SUCCESS:
      return {
        ...state,
        campaignById: action.payload,
        loading: false,
        error: null,
      };
    case campaignActions.GET_CAMPAIGN_BY_ID_FAILURE:
      return {
        ...state,
        campaignById: null,
        loading: false,
        error: action.payload.error,
      };
    case campaignActions.UPDATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        updating: true,
      };
    case campaignActions.UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignById: action.payload,
        updating: false,
      };
    case campaignActions.UPDATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case campaignActions.CAMPAIGN_STATS_COMPARED_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        updating: true,
      };
    case campaignActions.CAMPAIGN_STATS_COMPARED_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignStatsComparedData: action.payload,
        updating: false,
      };
    case campaignActions.CAMPAIGN_STATS_COMPARED_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default campaignReducer;
