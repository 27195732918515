import {
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_ERROR,
  POST_ADMIN_VIEW_CLIENT_LOGIN,
  POST_ADMIN_VIEW_CLIENT_LOGIN_SUCCESS,
  POST_ADMIN_VIEW_CLIENT_LOGIN_ERROR
  } from "./actionTypes"
  
  const initialState = {
    admin_manage_clients: [],
    admin_manage_clients_loading: false,
    admin_manage_clients_error: null,
    last_page_fetched: 0,
    total_number_of_pages: 0,
    total_client_users: 0,
    admin_view_client_login_error: null,
    admin_view_client_login: [],
    admin_view_client_login_loading: false
  }
  
  const Clients = (state = initialState, action) => {
    switch (action.type) {
      case GET_CLIENTS:
        let page_number = 1
          page_number = action.payload.queryParams.page_number
  
        return {
          ...state,
          admin_manage_clients_loading: true,
          admin_manage_clients: (page_number === 1) ? [] : state.admin_manage_clients,
          last_page_fetched: state.last_page_fetched,
        }
  
      case GET_CLIENTS_SUCCESS:
        let uniqueClientUsers = [];
        const all_client_users = [...state.admin_manage_clients, ...action.payload.data]
        all_client_users.forEach(function (item) {
          const i = uniqueClientUsers.findIndex(x => x.id == item.id);
          if (i <= -1) {
            uniqueClientUsers.push(item);
          }
        });
        return {
          ...state,
          admin_manage_clients: uniqueClientUsers,
          last_page_fetched: parseInt(action.payload.additional_data?.page_number),
          total_number_of_pages: parseInt(action.payload.additional_data?.total_number_of_pages),
          total_client_users: parseInt(action.payload.additional_data?.total_client_users),
          admin_manage_clients_loading: false
        }
  
      case GET_CLIENTS_ERROR:
        return {
          ...state,
          admin_manage_clients_error: action.payload,
          admin_manage_clients_loading: false
        }

        case POST_ADMIN_VIEW_CLIENT_LOGIN:
          return {
            ...state,
            admin_view_client_login_loading: true
          }
    
        case POST_ADMIN_VIEW_CLIENT_LOGIN_SUCCESS:
          return {
            ...state,
            admin_view_client_login: action.payload,
            admin_view_client_login_loading: false
          }
    
        case POST_ADMIN_VIEW_CLIENT_LOGIN_ERROR:
          return {
            ...state,
            admin_view_client_login_error: action.payload,
            admin_view_client_login_loading: false
          }

      default:
        return state
    }
  }
  
  export default Clients;
  