import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  NEW_PASSWORD,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_ERROR,
  CREATE_PASSWORD,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_ERROR,
} from "./actionTypes"

const initialState_forgetPassword = {
  forgetSuccessMsg: null,
  forgetError: null,
  loading: false,
}

const forgetPassword = (state = initialState_forgetPassword, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
      }
      break
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessMsg: action.payload,
      }
      break
    case FORGET_PASSWORD_ERROR:
      state = { ...state, forgetError: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

const initialState_resetPassword = {
  resetPasswordSuccessMsg: null,
  resetPasswordError: null,
  loading: false,
}

const resetPassword = (state = initialState_resetPassword, action) => {
  switch (action.type) {
    case NEW_PASSWORD:
      state = {
        ...state,
        resetPasswordSuccessMsg: null,
        resetPasswordError: null,
        loading: true,
      }
      break
    case NEW_PASSWORD_SUCCESS:
      state = {
        ...state,
        resetPasswordSuccessMsg: action.payload,
        loading: false,
      }
      break
    case NEW_PASSWORD_ERROR:
      state = { ...state, resetPasswordError: action.payload, loading: false }
      break
    case CREATE_PASSWORD:
      state = {
        ...state,
        resetPasswordSuccessMsg: null,
        resetPasswordError: null,
        loading: true,
      }
      break
    case CREATE_PASSWORD_SUCCESS:
      state = {
        ...state,
        resetPasswordSuccessMsg: action.payload,
        loading: false,
      }
      break
    case CREATE_PASSWORD_ERROR:
      state = { ...state, resetPasswordError: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export { forgetPassword, resetPassword };
