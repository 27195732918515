import { STORAGE_KEY, bsh_isAdminUserDetailsPresent } from "helpers/cc_browser_storage_helper";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const Authmiddleware = (props) => {
  const userNotAuthenticated = !localStorage.getItem(STORAGE_KEY.AUTH_TOKEN) && !bsh_isAdminUserDetailsPresent()
  const redirectionRoute = localStorage.getItem(STORAGE_KEY.REDIRECT_PATH)
  if (userNotAuthenticated) {
    const location = useLocation();
    localStorage.setItem(STORAGE_KEY.REDIRECT_PATH, location.pathname);
    return (
      <>
        <Navigate to={{ pathname: "/login", search: "?session_expired=1", state: { from: props.location } }} />
      </>
    );
  }
  if (!!redirectionRoute) {
    localStorage.removeItem(STORAGE_KEY.REDIRECT_PATH);
    return (
      <>
        <Navigate to={{ pathname: redirectionRoute }} />
      </>
    );
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
