import React, { useState, useEffect } from "react";
import { MetaTags } from 'react-meta-tags';
import { Card, Container, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux"; 
import Breadcrumbs from "../../components/common/Breadcrumb";
import { RETAIN_CERTIFICATE_TIME_OPTIONS } from "../../helpers/constants";
import { saveCampaignRequest } from "../../store/campaigns/actions";
import CampaignForm from "../../components/CampaignComponent/CampaignForm";
import { useNavigate } from 'react-router-dom';

import '../../assets/scss/theme.scss';
import '../../assets/css/datatables.scss'
import i18n_keys from "i18n_keys";
import { t } from "i18next";

const retain_certificate_time_options = RETAIN_CERTIFICATE_TIME_OPTIONS

function NewCampaigns() {
  const [selectedRetainCertificate, setSelectedRetainCertificate] = useState(null);
  const [campaignName, setCampaignName] = useState('');
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.campaignReducer);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRetainCertificateChange = selectedOption => {
    setSelectedRetainCertificate(selectedOption);
  };

  const handleNameCampaignChange = e => {
    setCampaignName(e.target.value);
  };

  // Navigate to campaigns page only after successful campaign creation
  // Prevents navigation if there's an error or while the request is still loading
  useEffect(() => {
    if (isSubmitting && !loading && !error) {
      navigate('/campaigns');
    }
  }, [loading, error, isSubmitting, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      name: campaignName,
      retain_certificate_for: selectedRetainCertificate.value
    };
    setIsSubmitting(true);
    dispatch(saveCampaignRequest(requestData));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>New Campaign {t(i18n_keys.PAGE_TITLE_SUFFIX)}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="New Campaign" link={"/campaigns"} backOption={true} />
          <Row>
            <Col className="col-12">
                <CampaignForm
                  campaignName={campaignName}
                  selectedRetainCertificate={selectedRetainCertificate}
                  handleNameCampaignChange={handleNameCampaignChange}
                  handleRetainCertificateChange={handleRetainCertificateChange}
                  handleSubmit={handleSubmit}
                  retainCertificateOptions={retain_certificate_time_options}
                  isCreatingCampaign={loading}
                />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default NewCampaigns;
