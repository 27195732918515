import { columnHeaderFormatter } from "helpers/utlis";
import * as Formatters from "utils/Formatter";
import { capitalizeWord, STATUS_OPTIONS } from "utils/common_utils";

export const columns = [
  {
    dataField: 'first_name',
    text: 'Account Owner Name',
    sort: true,
    formatter: Formatters.nameFormatter,
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
      return columnHeaderFormatter(column, colIndex, { sortElement, filterElement }, column.text)
    },
    columnName: 'account_name'
  },
  {
    dataField: 'accounts[0][status]',
    text: 'Status',
    sort: true,
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
      return columnHeaderFormatter(column, colIndex, { sortElement, filterElement }, column.text)
    },
    formatter: (_cellContent, row, rowIndex) => (
      <div className="d-flex gap-3 justify-content-start">
        {(
          <span className='fsnormal'>
            {_cellContent ?
              <>
                {accountStatusIcon(_cellContent)}
                <span className='text-black'>{capitalizeWord(_cellContent)}</span>
              </>
              : '--'}
          </span>

        )
        }
      </div>
    ),
    columnName: 'status'
  },
  {
    dataField: "accounts[0][account_dynamic_data][num_active_campaigns]",
    text: "Campaign Status",
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
      return columnHeaderFormatter(column, colIndex, { sortElement, filterElement }, column.text)
    },
    sort: true,
    sortFunc: (a, b, order, dataField, rowA, rowB) => {
      const num_active_campaigns_a = rowA?.accounts?.[0]?.account_dynamic_data?.num_active_campaigns;
      const num_active_campaigns_b = rowB?.accounts?.[0]?.account_dynamic_data?.num_active_campaigns;
      const num_inactive_campaigns_a = rowA?.accounts?.[0]?.account_dynamic_data?.num_inactive_campaigns;
      const num_inactive_campaigns_b = rowB?.accounts?.[0]?.account_dynamic_data?.num_inactive_campaigns;

      if (order === 'asc') {
        if (num_active_campaigns_a !== num_active_campaigns_b) {
          return num_active_campaigns_a - num_active_campaigns_b;
        } else {
          return num_inactive_campaigns_a - num_inactive_campaigns_b;
        }
      } else {
        if (num_active_campaigns_a !== num_active_campaigns_b) {
          return num_active_campaigns_b - num_active_campaigns_a;
        } else {
          return num_inactive_campaigns_b - num_inactive_campaigns_a;
        }
      }
    },
    formatter: (_cellContent, row) => {
      const num_active_campaigns = row?.accounts?.[0]?.account_dynamic_data?.num_active_campaigns;
      const num_inactive_campaigns = row?.accounts?.[0]?.account_dynamic_data?.num_inactive_campaigns;
      if (num_active_campaigns && num_inactive_campaigns) {
        return (<div className="d-flex gap-3 justify-content-start">
          <span>{`${num_active_campaigns} Active ${num_inactive_campaigns} Paused`}</span>
        </div>)
      } else if (num_active_campaigns && !num_inactive_campaigns) {
        return (<div className="d-flex gap-3 justify-content-start">
          <span>{`${num_active_campaigns} Active`}</span>
        </div>)
      } else if (!num_active_campaigns && num_inactive_campaigns) {
        return (<div className="d-flex gap-3 justify-content-start">
          <span>{`${num_inactive_campaigns} Paused`}</span>
        </div>)
      } else {
        return "--";
      }
    },
    columnName: 'campaign_status'
  },
  {
    dataField: 'location',
    text: 'Location',
    sort: true,
    formatter: Formatters.locationFormatter,
    columnName: 'location',
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
      return columnHeaderFormatter(column, colIndex, { sortElement, filterElement }, column.text)
    },
  },
  {
    dataField: 'accounts[0][created_at]',
    text: 'Account Creation',
    sort: true,
    formatter: Formatters.dateCreatedFormatter,
    columnName: 'date',
    headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
      return columnHeaderFormatter(column, colIndex, { sortElement, filterElement }, column.text)
    },
  },
]


export const defaultSorted = [{
  dataField: 'accounts[0].id',
  order: 'desc',
}];


export const accountStatusIcon = (value) => {
  let currentaccountStatusIcon = <i className='me-2 mdi mdi-circle font-size-10 text-muted' />
  switch (value) {
    case STATUS_OPTIONS.INACTIVE:
      currentaccountStatusIcon = <i className='me-2 mdi mdi-circle font-size-10 sfmuted' />
      break;
    case STATUS_OPTIONS.ACTIVE:
      currentaccountStatusIcon = <i className='me-2 mdi mdi-circle font-size-10 sfgreen' />
      break;
    case STATUS_OPTIONS.PAUSED:
      currentaccountStatusIcon = <i className='me-2 mdi mdi-circle font-size-10 sfred' />
      break;
    case STATUS_OPTIONS.ONBOARDING:
      currentaccountStatusIcon = <i className='me-2 mdi mdi-circle font-size-10 sfyellow' />
      break;
    default: currentaccountStatusIcon
      break;
  }
  return currentaccountStatusIcon
}

