// store/auth/actions.js

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, GET_CURRENT_CLIENT_USER_SUCCESS, GET_CURRENT_CLIENT_USER_ERROR, GET_CURRENT_CLIENT_USER } from './actionTypes';

export const loginRequest = (email, password, navigate) => ({
  type: LOGIN_REQUEST,
  payload: { email, password, navigate },
});

export const loginSuccess = (userData) => ({
  type: LOGIN_SUCCESS,
  payload: userData,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});


export const getCurrentClientUser = () => {
  return {
    type: GET_CURRENT_CLIENT_USER,
  }
}

export const getCurrentClientUserSuccess = user => {
  return {
    type: GET_CURRENT_CLIENT_USER_SUCCESS,
    payload: user
  }
}

export const getCurrentClientUserError = error => {
  return {
    type: GET_CURRENT_CLIENT_USER_ERROR,
    payload: error
  }
}