import Breadcrumb from 'components/common/Breadcrumb'
import i18n_keys from 'i18n_keys'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from 'reactstrap'
import { updateAdminUserCurrentUser } from 'store/admin/Authentication/login/actions'
import ScanQrCodeModal from './Modals/ScanQrCodeModal'
import { QRCodeSVG } from 'qrcode.react'

const Settings = () => {
  const {
    current_user,
    loading
  } = useSelector(state => ({
    current_user: state.LoginAdminUser.current_user,
    loading: state.LoginAdminUser.loading
  }))
  const dispatch = useDispatch();

  const [isMFAEnabled, setIsMFAEnabled] = useState(false);
  const [isEmailAndAppOTPEnabled, setIsEmailAndAppOTPEnabled] = useState(true);
  const [scanQRCodeState, setScanQRCodeState] = useState({});


  const resetingMFAState = () => {
    setIsMFAEnabled(current_user?.is_mfa_enabled);
    setIsEmailAndAppOTPEnabled(current_user?.is_email_otp_enabled);
  };

  useEffect(() => {
    resetingMFAState();
  }, [current_user]);

  const onMFAChecked = (e) => {
    setIsMFAEnabled(e.target.checked);
  };

  const onHandleEmailAndAppOTPChecked = (value) => {
    setIsEmailAndAppOTPEnabled(value);
  };

  const onSaveMFAConfirmationFromModal = (row) => {
    onSaveMFA();
    setScanQRCodeState({ action: false, data: {} });
  };

  const onSaveMFA = () => {
    const data = {
      admin_user: {
        id: current_user?.id,
        is_mfa_enabled: isMFAEnabled,
        is_email_otp_enabled: isEmailAndAppOTPEnabled,
      }
    };
    dispatch(updateAdminUserCurrentUser(data))
  };

  const onSaveMFAConfirmation = (e) => {
    e.preventDefault();
    if (isMFAEnabled && !isEmailAndAppOTPEnabled) {
      setScanQRCodeState({ action: true, otp_provisioning_url: encodeURI(current_user?.otp_provisioning_url), showQRCode: true });
      return;
    }
    onSaveMFA();
  };

  return (
    <div
      className="page-content" id="page-content-wrapper">
      <MetaTags>
        <title>{t(i18n_keys.ADMIN_SETTING) + t(i18n_keys.PAGE_TITLE_SUFFIX)}</title>
      </MetaTags>
      <ScanQrCodeModal
        state={scanQRCodeState}
        onConfirm={() => onSaveMFAConfirmationFromModal()}
        onCloseStateEvent={() => {
          setScanQRCodeState({ action: false, data: {} });
          resetingMFAState();
        }}
      />
      <Container fluid>
        <Breadcrumb title="Admin" breadcrumbItem="Settings" />
        <Row>
          <Col md={6}>
            <Card style={{ height: '450px' }}>
              <CardBody>
                <h4 className="card-title text-black mb-4">Authentication Settings</h4>
                <Col lg="12">
                  <div className="mb-3 d-flex justify-content-between">
                    <label className="d-flex align-items-center form-check-label" htmlFor="is-multi-factor-authentication">
                      Multi Factor Authentication
                      <i id='mfa-tooltip' className='ms-1 bx bx-info-circle text-black bx-xs'></i>
                      <UncontrolledTooltip placement="top" target='mfa-tooltip'>
                        MFA provides an extra level of security to your account and is recommended.
                      </UncontrolledTooltip>
                    </label>
                    <div className="form-check form-switch form-switch-md red-green-bg-switch-btn">
                      <input
                        name="is-multi-factor-authentication"
                        id="is-multi-factor-authentication"
                        type="checkbox"
                        className="form-check-input"
                        onChange={() => { }}
                        disabled={true}
                        onClick={onMFAChecked}
                        checked={isMFAEnabled}
                      />
                      <span className="font-weight-semibold">{isMFAEnabled ? 'On' : 'Off'}</span>
                    </div>
                  </div>
                </Col>
                <Col lg="12">
                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="is_multi_authentication"
                        value="email_authentication"
                        id="email-authentication"
                        autoComplete="off"
                        onChange={() => { }}
                        onClick={() => onHandleEmailAndAppOTPChecked(true)}
                        checked={isEmailAndAppOTPEnabled}
                        disabled={!isMFAEnabled}
                      />
                      <label className="d-flex align-items-center form-check-label" htmlFor="email-authentication">
                        Email Authentication
                        <i id='email-auth-tooltip' className='ms-1 bx bx-info-circle text-black bx-xs'></i>
                        <UncontrolledTooltip placement="top" target='email-auth-tooltip'>
                          Requires the user to enter a number code that they receive in their email when they log in.
                        </UncontrolledTooltip>
                      </label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-check mb-3">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="is_multi_authentication"
                        value="app_authentication"
                        id="google-app-authentication"
                        autoComplete="off"
                        onChange={() => { }}
                        onClick={() => onHandleEmailAndAppOTPChecked(false)}
                        checked={!isEmailAndAppOTPEnabled}
                        disabled={!isMFAEnabled}
                      />
                      <label className="d-flex align-items-center form-check-label" htmlFor="google-app-authentication">
                        Google App Authentication
                        <i id='google-app-auth-tooltip' className='ms-1 bx bx-info-circle text-black bx-xs'></i>
                        <UncontrolledTooltip placement="top" target='google-app-auth-tooltip'>
                          Requires the user to use the Google Authenticator app to acquire a number code that they use to log in.
                        </UncontrolledTooltip>
                      </label>
                    </div>
                    <div className="mb-3">
                      {
                        (isMFAEnabled && !isEmailAndAppOTPEnabled)
                          ? (<QRCodeSVG value={current_user?.otp_provisioning_url} size={180} className='shadow-lg d-flex flex-row sf-qr-code' />)
                          : (
                            <Card
                              className="shadow-lg d-flex flex-row justify-content-center align-items-center"
                              style={{ width: '180px', height: '180px' }}
                            >
                              <i className="fa fa-qrcode fa-5x text-light" aria-hidden="true" />
                            </Card>
                          )
                      }

                    </div>
                  </div>
                </Col>
                <Col lg="12">
                  <div className="d-flex flex-wrap gap-2">
                    <button className={`btn btn-primary w-lg`} type="submit" onClick={onSaveMFAConfirmation}>{loading ? 'Please Wait...' : 'Save'}</button>
                    <button className="btn btn-light w-lg" type="reset" onClick={resetingMFAState}>Cancel</button>
                  </div>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Settings