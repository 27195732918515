import React, { useState, useEffect } from "react"
import {
    Alert,
    Form,
    Input,
    FormFeedback,
    Label,
} from "reactstrap"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useNavigate } from "react-router-dom"
import { AppConfiguration } from "config"
import { loginAdminUser, resetError } from "store/admin/Authentication/login/actions"
import { useDispatch, useSelector } from "react-redux"
import useClearableTimeout from "hooks/useClearableTimeout"
import { errorMessages } from "common/utils/data_validations"
import { ADMIN_SIGNUP_FIELD_NAMES, ERR_DEVISE_SIGNATURE_VERIFICATION_FAILED } from "helpers/constants"
import logoDark from '../../../assets/images/logo-dark.png'
import i18n_keys from "i18n_keys"
import { useTranslation } from "react-i18next"
import { getFieldProps } from "helpers/utlis"
const AdminUserLogin = () => {
    const [flashMessageState, setFlashMessageState] = useState(null);

    const urlParams = new URLSearchParams(location.search)
    const [flashMessage, setFlashMessage] = useState({
        type: urlParams.get("flash_message_type"),
        text: urlParams.get("flash_message_text")
    })

    const session_expired = urlParams.get('session_expired')

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const query = new URLSearchParams(location.search);
    const { t } = useTranslation();

    const showOtp = query.get('showOtp');

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: "",
            password: "",
            otp: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email().required(t(i18n_keys.EMAIL_REQUIRED)),
            password: Yup.string().required(t(i18n_keys.PASSWORD_REQUIRED)),
            otp: showOtp ? Yup.string().matches(/^\d+$/, t(i18n_keys.OTP_NUMBER)).required(t(i18n_keys.OTP_REQUIRED)) : null,
        }),
        onSubmit: values => {
            const withoutOtpData = { admin_user: { email: values.email, password: values.password, } }
            if (values?.otp === "") {
                dispatch(loginAdminUser(withoutOtpData, navigate));
                return;
            }
            const data = { admin_user: { ...values } }
            dispatch(loginAdminUser(data, navigate));
        },
    })

    const [otpTimeoutSet] = useClearableTimeout();

    const handleFlashMessage = () => {
        setFlashMessageState(flashMessage);
        otpTimeoutSet(() => {
            setFlashMessageState(null)
        }, 5000)
    }

    useEffect(() => {
        handleFlashMessage();
    }, [flashMessage])

    const { loading, error } = useSelector(state => ({
        error: state.LoginAdminUser.error,
        loading: state.LoginAdminUser.loading,
    }));

    useEffect(() => {
        if (!error) {
            return;
        }
        setFlashMessage({ type: "danger", text: error })
        dispatch(resetError())
    }, [error])

    useEffect(() => {
        if (session_expired) {
            setFlashMessage({ type: "danger", text: errorMessages[ERR_DEVISE_SIGNATURE_VERIFICATION_FAILED] })
        }
    }, [session_expired])


    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5 mb-5">
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card overflow-hidden mt-5">
                                <div className="bg-white">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="text-center p-4 pb-2">
                                                <img src={logoDark} height="70" alt="Logo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pb-5 card-body ms-2 me-2">
                                    {/*  ------------ FLASH MESSAGE FOR REGISTRATION SUCCESS ------------ */}
                                    {flashMessageState && flashMessageState?.text ? (
                                        <Alert color={flashMessageState?.type}>
                                            {flashMessageState?.text}
                                        </Alert>
                                    ) : null}

                                    {/*  ------------ FLASH MESSAGE FOR REGISTRATION SUCCESS ------------ */}

                                    <Form
                                        className="form-horizontal"
                                        onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                    >
                                        {!showOtp ? (<>
                                            <div className="mb-3">
                                                <Label className="form-label font-weight-semibold">
                                                    Email
                                                </Label>
                                                <Input
                                                    {...getFieldProps(
                                                        ADMIN_SIGNUP_FIELD_NAMES.EMAIL,
                                                        validation,
                                                        { className: "form-control", placeholder: "Enter email", type: ADMIN_SIGNUP_FIELD_NAMES.EMAIL },
                                                        ["name", "className", "placeholder", "type", "onChange", "onBlur", "value", "invalid"]
                                                    )}
                                                />
                                                {
                                                    getFieldProps(
                                                        ADMIN_SIGNUP_FIELD_NAMES.EMAIL,
                                                        validation,
                                                        { className: "form-control", placeholder: "Enter email", type: ADMIN_SIGNUP_FIELD_NAMES.EMAIL },
                                                        ["feedback"]
                                                    ).feedback
                                                }
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label font-weight-semibold">
                                                    Password
                                                </Label>
                                                <Input
                                                    {...getFieldProps(
                                                        ADMIN_SIGNUP_FIELD_NAMES.PASSWORD,
                                                        validation,
                                                        { placeholder: "Enter Password", type: ADMIN_SIGNUP_FIELD_NAMES.PASSWORD },
                                                        ["name", "type", "placeholder", "onChange", "onBlur", "value", "invalid"]
                                                    )}
                                                />
                                                {
                                                    getFieldProps(
                                                        ADMIN_SIGNUP_FIELD_NAMES.PASSWORD,
                                                        validation,
                                                        { placeholder: "Enter Password", type: ADMIN_SIGNUP_FIELD_NAMES.PASSWORD },
                                                        ["feedback"]
                                                    ).feedback
                                                }
                                            </div>

                                        </>) : (
                                            <div className="mb-3">
                                                <Label className="form-label font-weight-semibold">Enter OTP</Label>
                                                <Input
                                                    {...getFieldProps(
                                                        "otp",
                                                        validation,
                                                        { placeholder: "Enter OTP", type: "text", autoFocus: true },
                                                        ["name", "type", "placeholder", "autoFocus", "onChange", "onBlur", "value", "invalid"]
                                                    )}
                                                />


                                                {
                                                    getFieldProps(
                                                        ADMIN_SIGNUP_FIELD_NAMES.OTP,
                                                        validation,
                                                        { placeholder: "Enter Otp", type: ADMIN_SIGNUP_FIELD_NAMES.OTP },
                                                        ["feedback"]
                                                    ).feedback
                                                }
                                            </div>
                                        )}
                                        <div className="mt-4 d-grid">
                                            <button
                                                className={`btn btn-primary btn-block`}
                                                type="submit"
                                                disabled={loading}
                                            >
                                                {loading ? "Please wait..." : "Log In"}
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className="mt-5 text-center text-white">
                                <p>
                                    Don&#39;t have an account ?{" "}
                                    <Link to="/admin_signup" className="fw-medium text-sfblue">
                                        Signup now
                                    </Link>
                                </p>
                                <p>
                                    © {new Date().getFullYear()} {AppConfiguration.WEBSITE}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AdminUserLogin
